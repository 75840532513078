<template>
  <div>
    <!--    Order Header    -->
    <h5 class="mb-1">
      {{ $t('ImplementationDetails') }}
    </h5>
    <!-- Order Details -->
    <b-card>
      <div
        v-if="cart && cart.contactThread"
        class="d-flex flex-wrap flex-column flex-md-row"
        style="row-gap: .5rem; column-gap: .5rem;"
      >
        <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
          <p class="mb-0">
            {{ $t('Name') }}:
          </p>

          <p class="h6 mb-0 d-inline-flex align-items-center">
            {{ cart.name }}
          </p>
        </div>

        <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
          <p class="mb-0">
            {{ $t('Contact') }}:
          </p>

          <p class="h6 mb-0 d-inline-flex align-items-center">
            {{ cartContact }}
          </p>
        </div>

        <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
          <p class="mb-0">
            {{ $t('Thread') }}:
          </p>

          <p class="h6 mb-0 d-inline-flex align-items-center">
            {{ cart.contactThread.name }}
          </p>
        </div>

        <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
          <p class="mb-0">
            {{ $t('Products') }}:
          </p>

          <div v-if="products.length">
            <transition-group name="slide-fade">
              <small
                v-for="(product, index) of productsToShow"
                :key="`product_${index}`"
              >
                <b-badge class="mt-25 ml-25">
                  {{ product.amount }}x {{ productName(product) | truncate(25) }}
                </b-badge>
              </small>
            </transition-group>

            <div
              v-if="products.length > 2"
              class="text-primary font-small-4 cursor-pointer mt-25"
              @click="allItemsShowing = !allItemsShowing"
            >
              {{ !allItemsShowing ? $t('ShowMore') : $t('ShowLess') }}
            </div>
          </div>
        </div>
      </div>
      <!--    Order Options    -->
    </b-card>

    <!--    Order Prices    -->
    <b-card style="position: sticky; top: 7rem;">
      <b-row>
        <!--    Order Details    -->
        <b-col
          sm="12"
          class="d-flex flex-wrap flex-column flex-md-row"
          style="row-gap: .5rem; column-gap: .5rem;"
        >
          <div
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
            class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
          >
            <p class="mb-0">
              {{ $t('Offer.TotalNetPrice') }}:
            </p>

            <p class="h6 mb-0">
              {{ totalProductsNetPrice | priceFormat }} PLN
            </p>
          </div>

          <div
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
            class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
          >
            <p class="mb-0">
              {{ $tc('Offer.Discount', 2) }}:
            </p>

            <p class="h6 mb-0 text-success">
              {{ (totalProductsNetPriceAfterSellerDiscount + totalSellerDiscount) - totalProductsNetPrice | priceFormat }} PLN
            </p>
          </div>

          <div
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
            class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
          >
            <p class="mb-0">
              {{ $t('UserDiscount') }}:
            </p>

            <p class="h6 mb-0 text-success">
              {{ totalSellerDiscount | priceFormat }} PLN
            </p>
          </div>

          <div
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
            class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
          >
            <p class="mb-0">
              {{ $t('Offer.TotalNetPriceAfterDiscount') }}:
            </p>

            <p class="h6 mb-0">
              {{ totalProductsNetPriceAfterSellerDiscount | priceFormat }} PLN
            </p>
          </div>

          <div
            v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
            class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
          >
            <p class="mb-0">
              {{ $t('Offer.ValueVAT') }}:
            </p>

            <p class="h6 mb-0">
              {{ totalProductsGrossPrice - totalProductsNetPriceAfterSellerDiscount | priceGrossFormat }} PLN
            </p>
          </div>
        </b-col>

        <hr class="separator my-1">

        <!--    Order Total    -->
        <b-col sm="12">
          <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
            <h6>
              {{ $t('Offer.TotalGrossPrice') }}:
            </h6>

            <h5>
              {{ totalProductsGrossPrice | priceGrossFormat }} PLN
            </h5>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { grossPrice, netPriceAfterCartDiscount, netPriceByAmount } from '@/helpers/cartProductPrices'

export default {
  name: 'CheckoutSummary',
  data: () => ({
    allItemsShowing: false,
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      products: 'cart/getCartProducts',
    }),
    productsToShow() {
      return !this.allItemsShowing ? this.products.slice(0, 2) : this.products
    },
    totalProductsGrossPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    totalProductsNetPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceByAmount(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    totalProductsNetPriceAfterDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceAfterCartDiscount(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    totalSellerDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.productSellerDiscount(b)), 0) : 0
    },
    totalProductsNetPriceAfterSellerDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceAfterCartDiscount(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    cartContact() {
      return `${this.cart.contactThread.contact?.firstName} ${this.cart.contactThread.contact?.lastName}`
    },
  },
  methods: {
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    productSellerDiscount(product) {
      // if (this.$store.state.cart.sellerDiscountEnabled && product.sellerDiscount) {
      if (this.$store.state.cart.sellerDiscountEnabled && product.sellerDiscount) {
        return parseFloat(product.sellerDiscount)
      }

      return 0
    },
    productName(product) {
      return product.shopProduct?.translations[0]?.name || '-'
    },
  },
}
</script>

<style scoped>

</style>
