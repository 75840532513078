<template>
  <div class="position-relative">
    <validation-observer
      ref="payments"
      class="mx-4"
    >
      <div
        class="pt-1"
      >
        <b-row
          class="d-flex flex-row justify-content-center"
        >
          <b-col
            sm="12"
            md="12"
            lg="8"
            class="pl-lg-25 pl-md-1 mb-1 mb-lg-0 position-relative"
          >
            <b-card>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Offer.Price')"
                rules="required"
              >
                <b-form-group :state="errors.length > 0 ? false:null">
                  <sw-select
                    :name="$t('SpecifyMethodOfPayment')"
                    class="mb-0"
                  >
                    <v-select
                      v-model="$store.state.cart.paymentType"
                      :state="errors.length > 0 ? false:null"
                      :options="paymentTypes"
                      :reduce="item => item.value"
                      :selectable="type => !type.disabled"
                      @input="changeSettings($event, 'paymentType')"
                      @option:selected="selectPaymentType"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>

                      <template #option="{ label }">
                        {{ $t(label) }}
                      </template>

                      <template #selected-option="{ label }">
                        {{ $t(label) }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-form-group>
              </validation-provider>
            </b-card>

            <b-card
              v-if="$store.state.cart.paymentType !== 'LOYALTY_POINTS'"
              class="mt-1"
            >
              <div class="d-flex justify-content-between align-items-center pr-50">
                <h4>{{ $t('Costs.AmountToPay') }}</h4>
              </div>

              <div class="d-flex flex-column flex-md-row justify-content-between align-content-center">
                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - price -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Offer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      v-if="$store.state.cart.cartPayments[1]"
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <cleave
                        :value="restToBeWritten"
                        :state="errors.length > 0 ? false:null"
                        :options="cleaveOptions.number"
                        class="form-control"
                        disabled
                        @blur="updatePaymentField(parseFloat($event), 'value', $store.state.cart.cartPayments[1].id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div class="flex-grow-1 mr-50">
                  <!-- Payment schedule - term -->
                  <validation-provider
                    v-if="$store.state.cart.cartPayments[1]"
                    v-slot="{ errors }"
                    :name="$t('Term')"
                    rules="required"
                  >
                    <b-form-group
                      class="pb-25"
                      :state="errors.length > 0 ? false:null"
                    >
                      <flat-pickr
                        v-model="$store.state.cart.cartPayments[1].date"
                        :placeholder="$t('Term')"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                        @blur="updatePaymentField($event, 'date', $store.state.cart.cartPayments[1].id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>

              <div class="d-flex flex-wrap justify-content-between align-items-center pr-50 mb-1 mb-md-75">
                <h4>
                  {{ $t('AdvancePayment') }}
                </h4>

                <b-badge :variant="restToBeWritten.toFixed(2) < 0 ? 'light-danger' : (restToBeWritten.toFixed(2) >0 ? 'light-primary' : 'light-success')">
                  {{ $t('RemainsToBeWrittenOut') }}: {{ restToBeWritten | priceFormat }} PLN
                </b-badge>
              </div>

              <div
                v-if="cart.cartPayments[0]"
                class="d-flex flex-column flex-md-row justify-content-between align-content-center"
                style="column-gap: 1rem; row-gap: .5rem;"
              >
                <!--                  style="max-width: 30px"-->
                <b-form-checkbox
                  v-model="cart.cartPayments[0].isUsed"
                  @change="e => { if (!e) { cart.cartPayments[0].value = 0 } }"
                >
                  {{ isMobile ? $t('Advance') : '' }}
                </b-form-checkbox>

                <div class="flex-grow-1">
                  <!-- Payment schedule - price -->
                  <validation-provider
                    v-if="$store.state.cart.cartPayments[0]"
                    v-slot="{ errors }"
                    :name="$t('Offer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      class="mb-0"
                    >
                      <cleave
                        v-model="$store.state.cart.cartPayments[0].value"
                        :state="errors.length > 0 ? false:null"
                        :options="cleaveOptions.number"
                        class="form-control"
                        :disabled="!$store.state.cart.cartPayments[0].isUsed"
                        @blur="updatePaymentField(parseFloat($event), 'value', $store.state.cart.cartPayments[0].id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div class="flex-grow-1">
                  <!-- Payment schedule - term -->
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('TermTermOffer.Price')"
                    rules="required"
                  >
                    <b-form-group
                      v-if="$store.state.cart.cartPayments[0]"
                      :state="errors.length > 0 ? false:null"
                      class="mb-0"
                    >
                      <flat-pickr
                        v-model="$store.state.cart.cartPayments[0].date"
                        :placeholder="$t('Term')"
                        class="form-control"
                        :disabled="!$store.state.cart.cartPayments[0].isUsed"
                        :state="errors.length > 0 ? false:null"
                        :config="{ locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y', altInput: true }"
                        @blur="updatePaymentField($event, 'date', $store.state.cart.cartPayments[0].id)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </b-card>

            <!--        <b-card class="mt-1">-->
            <!--          <div class="d-flex justify-content-between align-items-center pr-50">-->
            <!--            <h4>{{ $t('Costs.AmountToPay') }}</h4>-->
            <!--          </div>-->

            <!--          <div class="d-flex justify-content-between">-->
            <!--            <div class="flex-grow-1 mr-50">-->
            <!--              &lt;!&ndash; Payment schedule - price &ndash;&gt;-->
            <!--              <validation-provider-->
            <!--                :name="$t('Offer.Price')"-->
            <!--                rules="required"-->
            <!--              >-->
            <!--                <b-form-group class="pb-25">-->
            <!--                  <cleave-->
            <!--                    :options="cleaveOptions.number"-->
            <!--                    class="form-control"-->
            <!--                    disabled-->
            <!--                  />-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </div>-->

            <!--            <div class="flex-grow-1 mr-50">-->
            <!--              &lt;!&ndash; Payment schedule - term &ndash;&gt;-->
            <!--              <validation-provider-->
            <!--                #default="{ errors }"-->
            <!--                :name="$t('Term')"-->
            <!--                rules="required"-->
            <!--              >-->
            <!--                <b-form-group class="pb-25">-->
            <!--                  <flat-pickr-->
            <!--                    :placeholder="$t('Term')"-->
            <!--                    class="form-control"-->
            <!--                    :config="{ locale: locale, altFormat: 'd-m-Y', altInput: true }"-->
            <!--                  />-->
            <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        </b-card>-->

            <b-card
              v-if="$store.state.cart.paymentType === 'INSTALLMENT'"
              class="mt-1"
            >
              <h4>{{ $t('installment') }}</h4>

              <div
                class="d-flex flex-wrap align-items-end"
                style="row-gap: .5rem; column-gap: .5rem;"
              >
                <div>
                  <label for="SelectNumberOfInstallments">{{ $t('SelectNumberOfInstallments') }}</label>
                  <b-form-input
                    id="SelectNumberOfInstallments"
                    v-model="installmentsCount"
                    type="number"
                    :formatter="item => Math.abs(item) > 25 ? 25 : Math.abs(item)"
                    :disabled="$store.state.cart.paymentType !== 'INSTALLMENT'"
                  />
                </div>
                <b-button
                  variant="primary"
                  :disabled="!installmentsCount"
                  @click="calculateInstallments(false)"
                >
                  {{ $t('AddInstallments') }}
                </b-button>
                <b-button
                  variant="primary"
                  :disabled="!installmentsCount"
                  @click="calculateInstallments(true)"
                >
                  {{ $t('Calculate') }}
                </b-button>
              </div>

              <!--    START::Specify the net commission amount    -->
              <b-row class="mt-1">
                <b-col
                  v-if="installmentsCount && cart.cartPayments.filter(p => p.type === 'INSTALLMENT').length"
                  sm="12"
                  class="py-50 mx-0 row"
                  style="row-gap: 1rem"
                >
                  <b-row style="row-gap: .5rem">
                    <b-col
                      v-for="(installment, i) of cart.cartPayments.filter(p => p.type === 'INSTALLMENT')"
                      :key="`installment_${i}`"
                      sm="12"
                      md="6"
                    >
                      <label class="mb-50">
                        {{ i + 1 }}. {{ $t('Installment') }}
                      </label>

                      <div
                        class="d-flex align-items-start"
                        style="column-gap: .5rem"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="(i + 1) + '. ' + $t('Offer.Price') + ' ' + $t('installment').toLowerCase()"
                          rules="required"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                            class="mb-0"
                          >
                            <cleave
                              v-model="installment.value"
                              :options="cleaveOptions.number"
                              class="form-control"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider :name="(i + 1) + '. ' + $t('Term') + ' ' + $t('installment').toLowerCase()">
                          <sw-select-fpr>
                            <flat-pickr
                              v-model="installment.date"
                              class="form-control"
                              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale), altFormat: 'd-m-Y' }"
                              :placeholder="$t('Term')"
                              @on-close="changeInstallmentsDates($event, i)"
                            />
                          </sw-select-fpr>
                        </validation-provider>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  v-else
                  sm="12"
                  class="text-center py-1 text-primary font-weight-bold"
                >
                  <feather-icon
                    size="17"
                    icon="XCircleIcon"
                  />
                  {{ $t('ThereAreNoInstallmentsToDisplay') }}
                </b-col>
              </b-row>
              <!--    END::Specify the net commission amount    -->
            </b-card>

            <div
              v-if="$store.state.cart.paymentType === 'LOYALTY_POINTS'"
              class="mt-1"
            >
              <b-row>
                <b-col
                  v-if="!sellerPlans.length"
                  sm="12"
                >
                  <b-alert
                    show
                    variant="warning"
                  >
                    <div class="alert-body">
                      {{ $t('SelectedSellerDontHaveAssignedPlan') }}
                    </div>
                  </b-alert>
                </b-col>
                <!-- No plan selected -->
                <b-col
                  v-else
                  sm="12"
                >
                  <b-alert
                    v-if="userRestPointsForPlan($store.state.cart.purchaseLoyaltyPlan) < 0"
                    show
                    variant="warning"
                  >
                    <div class="alert-body">
                      {{ $t('NotEnoughPointToCompleteOrder') }}
                    </div>
                  </b-alert>
                  <b-alert
                    v-if="!$store.state.cart.purchaseLoyaltyPlan"
                    show
                    variant="primary"
                  >
                    <div class="alert-body">
                      {{ $t('SelectLoyaltyPlanInfoBox') }}
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row
                v-for="(plan, index) in sellerPlans"
                :key="`plan_${index}_${plan.id}`"
              >
                <b-col sm="12">
                  <h4>
                    <b-form-radio
                      v-model="$store.state.cart.purchaseLoyaltyPlan"
                      :value="plan.id"
                      @change="updateCartLoyaltyPlan"
                    >
                      {{ plan.name }}
                    </b-form-radio>
                  </h4>
                </b-col>
                <b-col
                  md="6"
                  lg="4"
                >
                  <b-alert
                    show
                    variant="primary"
                  >
                    <div class="alert-body d-flex align-items-center">
                      <b-avatar
                        variant="light-primary"
                        class="mr-75"
                      >
                        <feather-icon
                          icon="HexagonIcon"
                          size="16"
                          class="mt-25"
                        />
                      </b-avatar>

                      <span class="d-flex flex-column">
                        <strong>{{ $t('RequiredNumberOfPoints') }}</strong>

                        <span class="text-body">{{ loyaltyPointsPriceByPlanId(plan.id) }}</span>
                      </span>
                    </div>
                  </b-alert>
                </b-col>

                <b-col
                  md="6"
                  lg="4"
                >
                  <b-alert
                    show
                    variant="primary"
                  >
                    <div class="alert-body d-flex align-items-center">
                      <b-avatar
                        variant="light-primary"
                        class="mr-75"
                      >
                        <feather-icon
                          icon="HexagonIcon"
                          size="16"
                          class="mt-25"
                        />
                      </b-avatar>

                      <span class="d-flex flex-column">
                        <strong>{{ $t('TheNumberOfPointsYouHave') }}</strong>
                        <span class="text-body">{{ plan.value || 0 }}</span>
                      </span>
                    </div>
                  </b-alert>
                </b-col>

                <b-col
                  md="6"
                  lg="4"
                >
                  <b-alert
                    show
                    :variant="userRestPointsForPlan(plan.id, plan.value) < 0 ? 'danger' : 'success'"
                  >
                    <div class="alert-body d-flex align-items-center">
                      <b-avatar
                        :variant="userRestPointsForPlan(plan.id, plan.value) < 0 ? 'light-danger' : 'light-success'"
                        class="mr-75"
                      >
                        <feather-icon
                          icon="HexagonIcon"
                          size="16"
                          class="mt-25"
                        />
                      </b-avatar>

                      <span class="d-flex flex-column">
                        <strong>{{ $t('RemainingPointsAfterPurchase') }}</strong>

                        <span class="text-body">{{ plan.value - (loyaltyPointsPriceByPlanId(plan.id) || 0) }}</span>
                      </span>
                    </div>
                  </b-alert>
                </b-col>

                <b-col sm="12">
                  <transition name="fade">
                    <b-card
                      v-if="productsNotInPlan(plan.id).length && $store.state.cart.purchaseLoyaltyPlan === plan.id"
                      :title="$t('SelectingNewPlanInfoBox', 1, { name: plan.name })"
                      :sub-title="$t('SelectingNewPlanInfoBoxSubtitle', 1, { name: plan.name })"
                    >
                      <b-badge
                        v-for="product in productsNotInPlan(plan.id)"
                        :key="`product_${product.id}_plan_${plan.id}`"
                        class="cursor-pointer"
                        @click="removeProduct(product.id)"
                      >
                        <feather-icon icon="XIcon" />
                        {{ product.shopProduct.translations[0].name }}
                      </b-badge>
                    </b-card>
                  </transition>
                  <hr>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col
            sm="12"
            lg="4"
          >
            <checkout-summary />

            <b-col sm="12">
              <!-- eslint-disable -->
              <b-button
                variant="primary"
                class="w-100"
                :disabled="isDisable()"
                @click="nextStep"
              >
                {{ checkRequiredModule('agreementModule') && checkRequiredModule('paymentModule') ? $t('Next') : (cart.type === 'OFFER' ? $t('Offer.CreateAnOffer') : $t('Offer.FinishYourOrder')) }}
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        no-wrap
        :show="!allowOffer"
        variant="transparent"
        :opacity="0.95"
        :blur="'10px'"
        rounded="sm"
      >
        <template #overlay>
          <div class="d-flex align-items-center flex-column">
            <h4>{{ $t('UnavailableContentForOfferGenerating') }}</h4>
            <b-button
              variant="primary"
              class="w-25"
              @click="nextStep"
            >
              {{ $t('Next') }}
            </b-button>
          </div>
        </template>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import Cleave from 'vue-cleave-component'
import { mapGetters } from 'vuex'
import {
  EDIT_CONTACT,
  GET_USER_CART,
  REMOVE_PRODUCT_FROM_CART,
  UPDATE_CART,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import CheckoutSummary from '@/views/offers/checkout/CheckoutSummary.vue'
import { grossPrice, loyaltyPointsPrice, netPriceAfterCartDiscount } from '@/helpers/cartProductPrices'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'

export default {
  name: 'OrderPayment',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave,
    CheckoutSummary,
  },
  props: {
    allowOffer: {
      type: Boolean,
      default: true,
    },
    allowAgreement: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    paymentType: '',
    installmentsCount: 0,
    installments: [],
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
    flatPickerConfig: {
      locale: [pl, en],
    },
    paymentPartList: [],
    plans: [],

    totalPaymentDate: '',
    userLoyaltyPlans: [],
  }),
  watch: {
    'cart.paymentType': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          // this.initPayments()
          this.paymentType = newValue
        }
      },
    },

    /* eslint-disable */
    '$store.state.cart.cartPayments'(nw) {
      if (nw?.length === 0) {
        this.$store.state.cart.cartPayments = [{
          type: 'ADVANCE',
          value: 0,
          date: (moment().add(1, 'month').format('YYYY-MM-DD')),
          isUsed: false,
        }, {
          type: 'TOTAL_PAYMENT',
          value: this.restToBeWritten,
          date: moment().format('YYYY-MM-DD'),
        }]
      }
    },
    async '$store.state.cart.seller'(nw) {
        if (nw) {
            const x = await axiosIns.get('1/loyaltyPlans/points/byUser/' + this.getObjectId(nw))
            this.userLoyaltyPlans = x.data.data
        } else {
            this.userLoyaltyPlans = []
        }
    },
    // zaliczka - ADVANCE
    // rata - INSTALLMENT
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      products: 'cart/getCartProducts',
    }),
    locale() {
      return this.$i18n.locale
    },
    totalProductsNetPriceAfterDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceAfterCartDiscount(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    totalProductsGrossPriceAfterDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b, this.$store.state.cart.sellerDiscountEnabled)), 0) : 0
    },
    totalScheduleItemsPrice() {
      return (this.$store.state.cart.cartPayments.filter(({ type }) => type !== 'TOTAL_PAYMENT') || []).reduce((a, b) => +a + +(b?.value || 0), 0)
    },
    totalLoyaltyPointsPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.loyaltyPointsPrice(b.shopProduct) * (b.amount || 1)), 0) : 0
    },
    userRestPoints() {
      return (this.$store.getters['auth/getCurrentUser'].loyaltyPointsAmount || 0) - this.totalLoyaltyPointsPrice
    },
    restToBeWritten() {
      return this.totalProductsGrossPriceAfterDiscount - this.totalScheduleItemsPrice
    },
    sellerPlans() {
      return this.userLoyaltyPlans?.length ? this.userLoyaltyPlans.map(plan => ({
        id: plan.loyaltyPlan.id,
        name: plan.loyaltyPlan.name,
        value: plan.loyaltyPlansAvailablePoints,
      })) : []
    },
    plansAvailableInProducts() {
      const allPlans = []
      this.products.forEach(({ shopProduct }) => {
        if (shopProduct.shopProductLoyaltyPlans?.length) {
          shopProduct.shopProductLoyaltyPlans.forEach(plan => {
            const exist = allPlans.find(p => p.id === plan.loyaltyPlan?.id)
            if (!exist) allPlans.push(plan.loyaltyPlan)
          })
        }
      })
      return allPlans
    },
    areAllProductsInLoyaltyPlan() {
      let flag = true
      this.products.forEach(product => {
        if (!product.shopProduct?.shopProductLoyaltyPlans?.length) flag = false
      })

      return flag
    },
    paymentTypes() {
      const types = [
        { value: 'BANK_INSTALLMENT', label: 'bank_installment', disabled: false },
        { value: 'INSTALLMENT', label: 'installment', disabled: false },
        { value: 'TRANSFER', label: 'transfer', disabled: false },
        { value: 'CASH', label: 'cash', disabled: false },
      ]

      if (this.checkRequiredModule('loyaltyModule')) {
        const disabled = !this.areAllProductsInLoyaltyPlan || this.$store.state.cart.type !== 'ORDER'
        types.push({ value: 'LOYALTY_POINTS', label: 'LoyaltyPoints', disabled },)
      }

      return types
    },
  },
  /* eslint-disable */
  async mounted() {
    if (this.checkRequiredModule('loyaltyModule')) {
      await this.loadPlans()
    }

    if (this.$store.state.cart.cartPayments?.length === 0) {
      await this.addAdvancePayment()
    }
  },
  methods: {
    async loadPlans() {
      if (this.checkRequiredModule('loyaltyModule') && this.checkRequiredPermissions([this.$roles.LOYALTY_PLAN_VIEW_ALL])) {
        this.isLoading = true

        try {
          const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

          this.plans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.isLoading = false
        }
      }
    },
    isDisable() {
      if (!this.$store.state.cart.paymentType) return true
      if (this.$store.state.cart.paymentType === 'INSTALLMENT' && !this.restToBeWritten.toFixed(2).includes('0.0')) return true
      if (this.$store.state.cart.paymentType === 'LOYALTY_POINTS' && (!this.$store.state.cart.purchaseLoyaltyPlan || this.userRestPointsForPlan(this.$store.state.cart.purchaseLoyaltyPlan, null) < 0)) return true

      return false
    },
    netPriceAfterCartDiscount,
    grossPrice,
    loyaltyPointsPrice,
    userRestPointsForPlan(planId, planValue = null) {
      let value = planValue
      if (!planId) return 0
      if (planValue === null) {
        const plan = this.sellerPlans.find(plan => plan.id === planId)
        if (plan) value = plan?.value || 0
      }

      return (value ?? 0) - (this.loyaltyPointsPriceByPlanId(planId) || 0)
    },
    productsInPlan(planId) {
      return this.products.filter(({ shopProduct }) => shopProduct?.shopProductLoyaltyPlans?.length && shopProduct.shopProductLoyaltyPlans.find(plan => plan?.loyaltyPlan.id === planId))
    },
    productsNotInPlan(planId) {
      return this.products.filter(({ shopProduct }) => shopProduct?.shopProductLoyaltyPlans?.length && !shopProduct.shopProductLoyaltyPlans.find(plan => plan?.loyaltyPlan.id === planId))
    },
    loyaltyPointsPriceByPlanId(planId) {
      let sum = 0
      this.productsInPlan(planId).forEach(product => {
        const plan = product.shopProduct?.shopProductLoyaltyPlans.find(plan => plan.loyaltyPlan?.id === planId)
        if (plan) {
          sum += (plan?.pointsPrice * product.amount) || -1
        }
      })
      return sum
    },
    changeInstallmentsDates(e, index) {
      const month = moment(new Date(e)).month()

      const payments = this.cart.cartPayments

      for (let i = 1; i <= payments.length; i++) {
        // if (this.$store.state.cart.cartPayments[i + index]) this.$set(this.$store.state.cart.cartPayments[i + index], 'date', moment().add(month + i - 1, 'month').format('YYYY-MM-DD'))
        if (this.$store.state.cart.cartPayments[i + index]) this.$set(this.$store.state.cart.cartPayments[i + index], 'date', moment(new Date(e)).add(i - 1, 'month').format('YYYY-MM-DD'))
      }

      // for (let i = index; index < installments.length; i++) {
      //   const installment = installments[index]
      //   console.log(installment)
      // }
    },
    updateCartLoyaltyPlan(e) {
      this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: { ...this.cart, loyaltyPlan: e }, updateFiles: false, skip: true })
    },
    async nextStep() {
      const isValid = await this.$refs.payments.validate()
      if (!isValid) return

      if (!this.allowAgreement || !this.checkRequiredModule('agreementModule') || !this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF], [this.$roles.CONTACT_THREAD_AGREEMENT_ADD])) {
        if (this.cart.files && this.cart.files.length) {
          const payload = { uploadedFiles: this.cart.files.filter(file => !file?.token), type: 'building' }
          const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

          this.$set(this.cart, 'files', [
            ...files,
            ...this.cart.files.filter(file => file?.token).map(file => file.token),
          ])
        }
        try {
          const requests = []
          let restPayment = null
          const payments = this.cart.cartPayments.filter(payment => {
            if (payment.type === 'TOTAL_PAYMENT') {
              restPayment = payment
              return false
            }
            if (payment.type === 'ADVANCE' && !payment?.isUsed) {
              return false
            }

            return payment
          }).map(payment => {
              if (this.$store.state.cart.paymentType === 'LOYALTY_POINTS') {
                  return { ...payment, type: 'LOYALTY_POINTS' }
              }

              return payment
          })
          const toUpdate = payments.filter(payment => payment.id).map(p => ({ ...p, value: parseFloat(p?.value || 0) }))
          const toCreate = payments.filter(payment => !payment.id && payment.date).map(p => ({ ...p, value: parseFloat(p?.value || 0) }))

          let totalScheduleItemsPrice = 0
          toCreate.forEach(payment => { totalScheduleItemsPrice += payment.value })
          toUpdate.forEach(payment => { totalScheduleItemsPrice += payment.value })

          const restToPaid = parseFloat(this.totalProductsGrossPriceAfterDiscount - totalScheduleItemsPrice)
          if (restToPaid) {
            toCreate.push({
              ...restPayment,
              value: restToPaid
            })
          }

          if (toUpdate.length && this.$store.state.cart.paymentType !== 'LOYALTY_POINTS') requests.push(axiosIns.patch('1/contacts/threads/cartDrafts/me/paymentScheduleItems', toUpdate))
          if (toCreate.length && this.$store.state.cart.paymentType !== 'LOYALTY_POINTS') requests.push(axiosIns.post('1/contacts/threads/cartDrafts/me/paymentScheduleItems', toCreate))

          const resp = await Promise.all(requests)
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }

        // Addresses
        if (this.cart.deliveryAddressType === 'OTHER_ADDRESS') {
          const response = await axiosIns.post('1/addresses', this.cart.otherAddress)

          if (response?.data?.data?.items.length) {
            const id = response?.data?.data?.items[0].id
            this.$set(this.cart, 'deliveryAddress', id)
            const ids = (this.cart.contactThread.contact?.deliveryAddresses?.map(i => this.getObjectId(i)).filter(Boolean) || [])
            ids.push(id)

            const contactPayload = {
              id: this.cart?.contactThread?.contact?.id,
              deliveryAddresses: ids,
            }
            await this.$store.dispatch(`contacts/${EDIT_CONTACT}`, contactPayload)
          }
        }

        await this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: { ...this.cart, orderNumber: Boolean(this.cart.orderNumber) ? this.cart.orderNumber : null } })

        try {
          const finalizeCart = await axiosIns.post('1/contacts/threads/cartDrafts/me/doFinalize')
          let path = ''
          if (this.checkRequiredModule('offerModule') && this.cart.type === 'OFFER') path = `/contact/${this.cart.contactThread?.contact?.id}/thread/${this.cart?.contactThread?.id}/offers`
          else if (this.checkRequiredModule('orderModule') && this.cart.type !== 'OFFER') path = `/contact/${this.cart?.contactThread?.contact?.id}/thread/${this.cart.contactThread.id}/orders`
          else if (this.checkRequiredModule('paymentModule')) path = `/contact/${this.cart?.contactThread?.contact?.id}/thread/${this.cart.contactThread.id}/payments`
          else path = `/contact/${this.cart?.contactThread?.contact?.id}/thread/${this.cart.contactThread.id}/timeline`

          await this.$router.push(path)
          const cart = await this.$store.dispatch(`cart/${GET_USER_CART}`)

          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }

      this.$emit('nextStep')
    },
    getParts(sum, length) {
      // contactThreadCartDraftPaymentScheduleItems
      // paymentType
      const left = Math.ceil(sum / length)
      const right = Math.floor(sum / length)
      const first = (sum - right * length) / right

      return Array.from({ length }, (_, i) => (i < first ? left : right))
    },
    async removeProduct(productId) {
      try {
        await this.$store.dispatch(`cart/${REMOVE_PRODUCT_FROM_CART}`, { productId })

        const index = this.products.findIndex(product => product.id === productId)
        if (index >= 0) this.products.slice(index, 1)
        this.showToast('success', this.$i18n.t('ProductRemovedFromCard'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    calculateInstallments(fillData = false) {
        const count = this.installmentsCount
        const restPrice = this.totalProductsGrossPriceAfterDiscount - (this.cart.cartPayments[0]?.value || 0)
        const singlePriceByCount = parseFloat((restPrice / count).toFixed(2))
        const advancePayment = this.cart.cartPayments[0]
        const installments = [advancePayment]

        for (let i = 1; i <= count; i++) {
          installments.push({
            value: fillData ? singlePriceByCount : 0,
            type: 'INSTALLMENT',
            date: fillData ? moment().add(i, 'month').format('YYYY-MM-DD') : ''
          })
        }

      this.$set(this.$store.state.cart, 'cartPayments', installments)
    },
    async updatePaymentField(value, field, paymentId) {
      return null
    },
    initPayments() {
      this.addAdvancePayment()
    },
    async addAdvancePayment() {
      try {
        // const resp = await axiosIns.post('1/contacts/threads/cartDrafts/me/paymentScheduleItems', emptyPayment)

        // if (resp?.data?.data?.items?.length) {
        this.$set(this.$store.state.cart, 'cartPayments', [{
          type: 'ADVANCE',
          value: 0,
          date: moment().format('YYYY-MM-DD'),
          isUsed: false,
        }])
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    savePayments() {
      const payload = this.cartPayments
      this.changeSettings(payload, 'contactThreadCartDraftPaymentScheduleItems', false)
    },
    selectPaymentType() {
        this.$set(this.$store.state.cart, 'cartPayments', [{
            type: 'ADVANCE',
            value: 0,
            date: moment().add(1, 'month').format('YYYY-MM-DD'),
            isUsed: false,
        }, {
            type: 'TOTAL_PAYMENT',
            value: 0,
            date: moment().format('YYYY-MM-DD'),
        }])
    },
    async changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$nextTick(() => {
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: { ...this.cart, [place]: value }, updateFiles: false, skip: true })
      })
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isMobile,
    }
  },
}
</script>
